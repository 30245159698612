import React from 'react'
import { Container } from 'reactstrap'

import Contact from 'components/Contact'

import Wrapper from './Wrapper'

const ContactWidget = ({ title, subtitle }) => (
  <Wrapper id="contact">
    <Container>
      <Contact title={title} subtitle={subtitle} />
    </Container>
  </Wrapper>
)

export default ContactWidget
