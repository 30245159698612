import React from 'react'
import { Row } from 'reactstrap'

import * as Routes from 'routes'

import * as CompanyMark from 'components/CompanyMark'
import Heading from 'components/Heading'
import H2 from 'components/H2'
import H4 from 'components/H4'
import Input from 'components/Input'
import Textarea from 'components/Textarea'
import Button from 'components/Button'

import iconLocation from 'images/icons/location.svg'
import iconMail from 'images/icons/mail.svg'

import Wrapper from './Wrapper'
import Information from './Information'
import Icon from './Icon'

const Contact = ({ title, subtitle }) => (
  <Wrapper
    action={Routes.CONTACT_SUCCESS}
    name="kontakt"
    method="POST"
    data-netlify="true"
    data-netlify-honeypot="bot-field"
  >
    <input type="hidden" name="form-name" value="kontakt" />
    <input type="hidden" name="bot-field" />
    <Row>
      <Heading noBackground>
        <H4>
          <CompanyMark.Left /> {subtitle} <CompanyMark.Right />
        </H4>
        <H2>{title}</H2>
      </Heading>
    </Row>
    <Row className="mt-5 mb-5 text-center">
      <Information className="mb-4 mb-md-0">
        <Icon src={iconLocation} alt="Miejsce" />
        <div className="text-center text-md-left d-inline-block">
          Karer Solutions GmbH
          <br/>
          Amtsgericht Bayreuth, HRB 7361
          <br />
          Kulmbacher Str. 68
          <br />
          95445 Bayreuth, DE
        </div>
      </Information>
      <Information>
        <Icon src={iconMail} alt="E-mail" /> info@karermedia.com
      </Information>
    </Row>
  </Wrapper>
)

export default Contact
