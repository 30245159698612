import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import { Container, Row } from 'reactstrap'
import Img from 'gatsby-image'

import * as Routes from 'routes'
import Layout from 'layouts/static'

import Main from 'components/Main'
import * as CompanyMark from 'components/CompanyMark'
import ButtonLink from 'components/Button/Link'
import Heading from 'components/Heading'
import H2 from 'components/H2'
import H3 from 'components/H3'
import H4 from 'components/H4'
import Cards from 'components/Card/Cards'
import * as Card from 'components/Card'
import CardLink from 'components/Card/Link'
import * as Icons from 'components/Icon'
import Portfolio from 'components/Portfolio'
import Steps, { List as StepsList } from 'components/Steps'
import * as Step from 'components/Steps/Step'
import Partners from 'components/Partners'
import ContactWidget from 'components/ContactWidget'

import AboutUs, { Mockup as AboutUsMockup } from 'views/home/AboutUs'
import AboutUsBackground from 'views/home/AboutUs/Background'
import PortfolioSection from 'views/home/Portfolio'

const HomePage = ({ data }) => (
  <Layout withAddon header={header}>
    <Helmet title="we build modern web services for your business">
      <meta name="description" content={data.site.siteMetadata.description} />
    </Helmet>

    <Main>
      <Heading>
        <H4>
          <CompanyMark.Left /> Products from scratch <CompanyMark.Right />
        </H4>
        <H2 className="text-xl-left">Let's build something great</H2>
      </Heading>

      <Cards>
        <Container>
          <Row>
            <div className="col-12 col-md px-4 mb-4 mb-lg-0">
              <Link>
                <Card.Wrapper>
                  <Card.Icon className="mx-auto">
                    <Icons.Websites alt="Websites" />
                  </Card.Icon>
                  <H3>Websites</H3>
                  <div className="mt-4">
                    We take care of everything in websites and online stores
                    aspect. From consulting, through design and software, to
                    implementation and SEO positioning. We will introduce your business into the Internet - from scratch to the very end.
                  </div>
                  {/* <CardLink>
                    Dowiedz się więcej <span>&gt;</span>
                  </CardLink> */}
                </Card.Wrapper>
              </Link>
            </div>

            <div className="col-12 col-md px-4 mb-4 mb-lg-0">
              <Link>
                <Card.Wrapper>
                  <Card.Icon className="mx-auto">
                    <Icons.Apps alt="Mobile applications" />
                  </Card.Icon>
                  <H3>Mobile applications</H3>
                  <div className="mt-4">
                    We build native and intuitive mobile applications for iOS and Android platforms - available on the App Store and Google Play. Looking for
                    a partner who will meet your expectations and additionally add
                    good practices from each other? You've come to a great place!
                  </div>
                  {/* <CardLink>
                    Dowiedz się więcej <span>&gt;</span>
                  </CardLink> */}
                </Card.Wrapper>
              </Link>
            </div>
            <div className="col-12 col-md px-4">
              <Link>
                <Card.Wrapper>
                  <Card.Icon className="mx-auto">
                    <Icons.Misc alt="Other services" />
                  </Card.Icon>
                  <H3>Other services</H3>
                  <div className="mt-4">
                    Cloud services, Machine Learning, CRMs, integrations,
                    automation and individual solutions. Passion for
                    creating products means that things do not exist for us
                    impossible. We have custom designs behind us
                    changed the faces of many companies.
                  </div>
                  {/* <CardLink>
                    Dowiedz się więcej <span>&gt;</span>
                  </CardLink> */}
                </Card.Wrapper>
              </Link>
            </div>
          </Row>
        </Container>
      </Cards>

      <AboutUs id="o-nas">
        <AboutUsBackground />
        <Container>
          <Row>
            <div className="col-12 col-xl align-self-end">
              <AboutUsMockup>
                <Img
                  fluid={data.aboutUsMockup.childImageSharp.fluid}
                  alt="O nas"
                />
              </AboutUsMockup>
            </div>
            <div className="col-12 col-xl">
              <Heading left secondary noBackground>
                <H4 className="text-center text-xl-left">
                  <CompanyMark.Left /> Your IT partner <CompanyMark.Right />
                </H4>
                <H2 className="text-center text-xl-left">Who are we?</H2>
                <div className="mt-5 text-center text-xl-left">
                  Karer Media is a Digital Agency different from the standard ones
                   IT companies. We do not limit ourselves to programming and we create
                   products from scratch to finish. Our experienced enthusiasts
                   they work at every stage - consulting, design,
                   programming and implementation. We focus on the final quality
                   always in the first place.
                  <br />
                  <br />
                  The development of your business in the internet sphere becomes with us
                   phenomenally simple. We will make sure that every detail is finished
                   the last button in all respects, incl. appearance,
                   functionality, efficiency, trends and intuitiveness of use.
                   The final product is one that we are fully satisfied with
                   together you and us.
                  <br />
                  <ButtonLink
                    to="#"
                    variant="secondary"
                    className="mt-4"
                    onClick={e => {
                      e.preventDefault()

                      document
                        .getElementById('nasze-realizacje')
                        .scrollIntoView({ behavior: 'smooth' })
                    }}
                  >
                    Get to known us better
                  </ButtonLink>
                </div>
              </Heading>
            </div>
          </Row>
        </Container>
      </AboutUs>

      {/* <Heading id="nasze-realizacje">
        <H4>
          <CompanyMark.Left /> Obrazy znaczą więcej, niż tysiąc słów{' '}
          <CompanyMark.Right />
        </H4>
        <H2>Nasze realizacje</H2>
      </Heading>

      <PortfolioSection>
        <Row>
          <Portfolio mode="home" />
        </Row>
        <Row>
          <ButtonLink
            to={Routes.PORTFOLIO}
            variant="primary"
            className="my-4 mx-auto"
          >
            Zobacz całe portfolio
          </ButtonLink>
        </Row>
      </PortfolioSection> */}

      {/* <Steps>
        <Container>
          <Row>
            <Heading noBackground light>
              <H4>
                <CompanyMark.Left /> Przebieg współpracy <CompanyMark.Right />
              </H4>
              <H2>Jak działamy?</H2>
            </Heading>
          </Row>
          <Row>
            <StepsList>
              <Step.Wrapper>
                <Step.Title>KROK 1</Step.Title>
                <Step.Subtitle>Warsztat</Step.Subtitle>
                <div>
                  Zanim przejdziemy do konkretów, poznajemy Ciebie i Twoje
                  oczekiwania. Proponujemy najoptymalniejsze rozwiązania,
                  kreujemy przekonania, dzielimy się doświadczeniem. Na żywo,
                  online, telefonicznie, przez Messengera - tak, jak jest Ci
                  najwygodniej. Rozpoczęcie współpracy od zaliczki? Nie u nas.
                </div>
              </Step.Wrapper>
              <Step.Wrapper>
                <Step.Title>KROK 2</Step.Title>
                <Step.Subtitle>Implementacja</Step.Subtitle>
                <div>
                  Kiedy wiemy czego oczekujesz, przechodzimy do sedna -
                  estymacje, specyfikacje i makiety. Z doświadczenia wiemy, że
                  potrzeby zmian zaczynają być widoczne dopiero w miarę rozwoju
                  projektu. Dzięki organizacji metodą Agile, na bieżąco trafnie
                  dostosowujemy pracę do zamierzonego celu.
                </div>
              </Step.Wrapper>
              <Step.Wrapper>
                <Step.Title>KROK 3</Step.Title>
                <Step.Subtitle>Wdrożenie i utrzymanie</Step.Subtitle>
                <div>
                  Kiedy my nieustannie pracujemy nad Twoim projektem, Ty
                  skupiasz się na prowadzeniu biznesu. Nawet po zakończeniu
                  prac, nadal doradzamy, proponujemy i do tego dajemy Ci
                  gwarancję na stworzony produkt. Jako partner biznesowy
                  wspólnie zależy nam przecież na jego sukcesie.
                </div>
              </Step.Wrapper>
            </StepsList>
          </Row>
        </Container>
      </Steps>

      <Partners /> */}

      <ContactWidget title="Contact" subtitle="Get to know us better" />
    </Main>
  </Layout>
)

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
        image
        description
      }
    }
    aboutUsMockup: file(relativePath: { eq: "page_home/aboutus_mockup.png" }) {
      childImageSharp {
        fluid(maxWidth: 721, maxHeight: 570) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const header = {
  title: (
    <Fragment>
      We build modern web services for your business<span>.</span>
    </Fragment>
  ),
  subtitle: (
    <Fragment>
      Hello. We are <CompanyMark.Left /> Karer Media <CompanyMark.Right />
    </Fragment>
  ),
  content: (
    <div>
      <div className="my-5">
        XXI century-grade. Innovate, stable and personalized.
      </div>
      <ButtonLink
        to="#"
        variant="primary"
        className="mb-2 mb-md-0"
        onClick={e => {
          e.preventDefault()

          document
            .getElementById('o-nas')
            .scrollIntoView({ behavior: 'smooth' })
        }}
      >
        About us
      </ButtonLink>
      <ButtonLink to={Routes.CONTACT} variant="secondary" className="ml-md-3">
        Get in touch
      </ButtonLink>
    </div>
  ),
  image: 'home'
}

export default HomePage
